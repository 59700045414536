/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Load Open Sans typeface
require("typeface-open-sans");
// Load Droid Sans typeface
require("typeface-droid-sans");
// Load Crimson Text typeface
require('typeface-crimson-text')
