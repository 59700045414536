// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-custom-website-development-js": () => import("./../src/pages/services/custom-website-development.js" /* webpackChunkName: "component---src-pages-services-custom-website-development-js" */),
  "component---src-pages-services-digital-marketing-js": () => import("./../src/pages/services/digital-marketing.js" /* webpackChunkName: "component---src-pages-services-digital-marketing-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-mobile-app-development-js": () => import("./../src/pages/services/mobile-app-development.js" /* webpackChunkName: "component---src-pages-services-mobile-app-development-js" */),
  "component---src-pages-services-search-engine-optimization-js": () => import("./../src/pages/services/search-engine-optimization.js" /* webpackChunkName: "component---src-pages-services-search-engine-optimization-js" */),
  "component---src-pages-services-website-design-js": () => import("./../src/pages/services/website-design.js" /* webpackChunkName: "component---src-pages-services-website-design-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

